import React from 'react';
import styled from 'styled-components';
import { TextSMMedium } from 'styleguide/Texts';
import { CustomizableIcon } from 'styleguide/CustomizableIcon';
import { Spacing, CornerRadius } from 'styleguide/spacing';
import { modal } from './Modals/ModalManager';
import { colors } from 'styleguide/colors';

const FeedbackButtonContainer = styled.div`
  position: fixed;
  right: ${Spacing.lg}px;
  @media (max-width: 1024px) {
    right: ${Spacing.md}px;
  }
  bottom: 0px;
  border-top-left-radius: ${CornerRadius.sm}px;
  border-top-right-radius: ${CornerRadius.sm}px;
  background-color: ${colors.primary500};
  cursor: pointer;
  padding: ${Spacing.sm}px;
  flex-direction: row;
  display: flex;
  align-items: center;
  gap: ${Spacing.sm}px;
`;

const DesktopFeedbackButtonContainer = styled.div`
  @media (max-width: 1024px) {
    display: none;
  }
`;
const MobileFeedbackButtonContainer = styled.div`
  @media (min-width: 1025px) {
    display: none;
  }
`;

export const FeedbackButton = () => {
  return (
    <>
      <DesktopFeedbackButtonContainer>
        <FeedbackButtonContainer onClick={modal.openFeedbackModal}>
          <CustomizableIcon type="paper-airplane" tint={colors.white} />
          <TextSMMedium style={{ color: colors.white }}>Send feedback</TextSMMedium>
        </FeedbackButtonContainer>
      </DesktopFeedbackButtonContainer>
      <MobileFeedbackButtonContainer>
        <FeedbackButtonContainer onClick={modal.openFeedbackModal}>
          <CustomizableIcon
            type="paper-airplane"
            tint={colors.white}
            style={{ height: Spacing.md, width: Spacing.md }}
          />
          <TextSMMedium style={{ color: colors.white, fontSize: 12 }}>Send feedback</TextSMMedium>
        </FeedbackButtonContainer>
      </MobileFeedbackButtonContainer>
    </>
  );
};
