import { DisplaySM, TextMD, TextMDRegular } from 'styleguide/Texts';
import React from 'react';
import styled from 'styled-components';
import { colors } from 'styleguide/colors';
import { Spacing } from 'styleguide/spacing';
import { Link } from 'components/Link';
import { FeedbackButton } from 'components/FeedbackButton';
import { View } from 'styleguide/View';

const BootstrapLoadingContainer = styled.div`
  flex: 1;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
`;

export default function Custom404() {
  return (
    <BootstrapLoadingContainer>
      <View style={{ padding: Spacing.lg }}>
        <DisplaySM>404</DisplaySM>
        <TextMDRegular>Sorry, we couldn't find that.</TextMDRegular>
        <Link href="/" style={{ paddingTop: Spacing.lg }}>
          <TextMD style={{ color: colors.primary500 }}>Go Back</TextMD>
        </Link>
        <FeedbackButton />
      </View>
    </BootstrapLoadingContainer>
  );
}
